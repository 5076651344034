import { appendQueryString } from '@florencecard-lib/query-string';
import Router from 'next/router';
import { useCallback, useEffect } from 'react';
import useSWR from 'swr';
import { useAlertDialog } from '~/hooks/alert-dialog';
import { authPersistence } from '~/persistences/auth';
import { myPersistence } from '~/persistences/my';
import { ApiErrorCodes, queryApiErrorCode, queryErrorMessage } from '~/remotes/api';
import { checkAuth, logout } from '~/remotes/auth';

interface AuthOptions {
  accessToken?: string;
  redirectIfUnauthorized?: boolean;
  unauthorizedRedirectUrl?: string;
}

export function useAuth(options: AuthOptions = {}) {
  const openAlert = useAlertDialog();
  const {
    accessToken: accessTokenFromOptions,
    redirectIfUnauthorized = true,
    unauthorizedRedirectUrl = '/login',
  } = options;

  const { data: user, error } = useSWR(
    ['auth', accessTokenFromOptions ?? authPersistence.get('accessToken')],
    async (_, accessToken?: string) => {
      if (accessToken == null) {
        return null;
      }

      return await checkAuth({ accessToken });
    },
    {
      revalidateOnFocus: false,
      dedupingInterval: 5_000,
    },
  );

  const logoutCurrentUser = useCallback(async () => {
    if (user?.accessToken == null) {
      return;
    }

    const { accessToken } = user;

    try {
      await logout({ accessToken });

      authPersistence.delete('accessToken');
      myPersistence.delete('passwordCheckedForEditProfile');

      window.location.href = '/';
    } catch (error) {
      openAlert({
        title: queryErrorMessage(error),
      });
    }
  }, [user, openAlert]);

  useEffect(() => {
    if (user != null) {
      authPersistence.set('accessToken', user.accessToken);
    }
  }, [user]);

  useEffect(() => {
    if (queryApiErrorCode(error) === ApiErrorCodes.인증에_실패함 || user === null) {
      authPersistence.delete('accessToken');
      myPersistence.delete('passwordCheckedForEditProfile');

      if (redirectIfUnauthorized) {
        Router.replace(
          appendQueryString(unauthorizedRedirectUrl, {
            redirect: '/my',
          }),
        );
      }
    }
  }, [user, error, redirectIfUnauthorized, unauthorizedRedirectUrl]);

  return [error != null ? null : user, { logout: logoutCurrentUser }] as const;
}
