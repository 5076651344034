import { Box, Button, Flex, Spacing, Stack, Txt } from '@florencecard/components';
import { colors } from '@florencecard-shared/colors';
import { css } from '@emotion/react';
import Link from 'next/link';
import Router from 'next/router';
import React, { ReactNode } from 'react';
import { useAuth } from '~/hooks/auth';

const hrefToLoginPage = () => {
  Router.push('/login');
};

export default function FooterNew() {
  const [user, { logout }] = useAuth({
    redirectIfUnauthorized: false,
  });

  return (
    <Box
      as="footer"
      backgroundColor={colors.grayBackground}
      paddingLeft={20}
      paddingRight={20}
      paddingTop={[32, 40]}
      paddingBottom={[32, 40]}
    >
      <Box maxWidth={1100 + 40} margin="0 auto">
        {/* Desktop */}
        <Box display={['none', 'block']}>
          <Flex alignItems="flex-start">
            <img
              src="https://assets.florencecard.me/images/ic-logo-gray@3x.png"
              alt="플로렌스카드"
              height={24}
            />
            <Box flex={1} />
            <Stack direction="horizontal" gutter={32}>
              <Sitemap
                title="제작하기"
                links={[
                  {
                    name: '모바일청첩장',
                    path: '/store-list',
                  },
                ]}
              />
              <Sitemap
                title="내 주문"
                links={[
                  {
                    name: '구매내역',
                    path: '/my/purchases',
                  },
                  {
                    name: '취소내역',
                    path: '/my/refunds',
                  },
                ]}
              />
              {/*<Sitemap*/}
              {/*  title="내 페이지"*/}
              {/*  titlePath="/my"*/}
              {/*  links={[*/}
              {/*    {*/}
              {/*      name: '참석의사 확인',*/}
              {/*      path: '/my/rsvp',*/}
              {/*    },*/}
              {/*    {*/}
              {/*      name: '방명록 관리',*/}
              {/*      path: '/my/guest-books',*/}
              {/*    },*/}
              {/*    {*/}
              {/*      name: '회원정보수정',*/}
              {/*      path: '/my/profile',*/}
              {/*    },*/}
              {/*  ]}*/}
              {/*/>*/}
              <Sitemap
                title="문의"
                links={[
                  {
                    name: '1:1 문의하기',
                    path: '/inquiry',
                  },
                  // {
                  //   name: '공지사항',
                  //   path: '/notice',
                  // },
                ]}
              />
            </Stack>
          </Flex>
          <Spacing size={40} />
          <Box as="hr" height={1} backgroundColor="#ced4da" border="none" margin={0} />
          <Spacing size={8} />
          <Flex
            alignItems="center"
            css={css`
              & > * ~ * {
                margin-left: 10px !important;
              }
            `}
          >
            <Link href="/policy/terms-of-use">
              <Txt as="a" fontSize={13} lineHeight="19px" color={colors.grayBold} cursor="pointer">
                이용약관
              </Txt>
            </Link>
            <Box width={1} height={14} backgroundColor={colors.grayLight} />
            <Link href="/policy/privacy-statement">
              <Txt as="a" fontSize={13} lineHeight="19px" color={colors.grayBold} cursor="pointer">
                개인정보처리방침
              </Txt>
            </Link>
          </Flex>
          <Spacing size={8} />
          <Flex
            alignItems="center"
            css={css`
              flex-wrap: wrap;

              & > *:not(:last-child) {
                margin-right: 8px !important;
              }
            `}
          >
            <InfoText>상호 : 플로렌스</InfoText>
            <Box width={1} height={13} backgroundColor={colors.grayLight} />
            <InfoText>대표자 : 조현민</InfoText>
            <Box width={1} height={13} backgroundColor={colors.grayLight} />
            <InfoText>개인정보책임자 : 이은미</InfoText>
            <Box width={1} height={13} backgroundColor={colors.grayLight} />
            <InfoText>사업자등록번호 : 168-01-02960</InfoText>
            <Box width={1} height={13} backgroundColor={colors.grayLight} />
            <InfoText>통신판매업신고번호 : 제 2022-성남분당A-0636</InfoText>
            <Box width={1} height={13} backgroundColor={colors.grayLight} />
            <InfoText>주소 : 경기도 성남시 분당구 판교역로 192번길 16, 806호</InfoText>
          </Flex>
          <Spacing size={8} />
          <Flex
            alignItems="center"
            css={css`
              flex-wrap: wrap;

              & > *:not(:last-child) {
                margin-right: 8px !important;
              }
            `}
          >
            <InfoText>
              문의 :{' '}
              <Txt as="a" href="mailto://florencecard2@gmail.com">
                florencecard2@gmail.com
              </Txt>{' '}
              또는{' '}
              <Txt as="a" href="tel://070-8095-5850">
                070-8095-5850
              </Txt>
            </InfoText>
            <Box width={1} height={13} backgroundColor={colors.grayLight} />
            <InfoText>COPYRIGHT© FLORENCE ALL RIGHT RESERVED</InfoText>
          </Flex>
        </Box>

        {/* Mobile */}
        <Box display={['block', 'none']}>
          <Stack direction="horizontal" alignItems="center" justifyContent="center" gutter={8}>
            <Button
              size={34}
              lineHeight="34px"
              border={`1px solid ${colors.grayBorder}`}
              borderRadius={6}
              fontSize={11}
              fontWeight={400}
              color={colors.grayRegular}
              onClick={user != null ? logout : hrefToLoginPage}
            >
              {user != null ? '로그아웃' : '로그인'}
            </Button>
            <Link href="/policy/terms-of-use">
              <Button
                as="a"
                size={34}
                lineHeight="34px"
                border={`1px solid ${colors.grayBorder}`}
                borderRadius={6}
                fontSize={11}
                fontWeight={400}
                color={colors.grayRegular}
              >
                이용약관
              </Button>
            </Link>
            <Link href="/policy/privacy-statement">
              <Button
                as="a"
                size={34}
                lineHeight="34px"
                border={`1px solid ${colors.grayBorder}`}
                borderRadius={6}
                fontSize={11}
                fontWeight={400}
                color={colors.grayRegular}
              >
                개인정보처리방침
              </Button>
            </Link>
          </Stack>
          <Spacing size={16} />
          <Txt
            display="block"
            width="100%"
            fontSize={11}
            color={colors.grayRegular}
            textAlign="center"
          >
            COPYRIGHT© FLORENCE ALL RIGHT RESERVED
          </Txt>
          <Spacing size="max(env(safe-area-inset-bottom), 48px)" />
        </Box>
      </Box>
    </Box>
  );
}

type Link = {
  name: string;
  path: string;
};

function Sitemap({
  title,
  titlePath,
  links,
}: {
  title: string;
  titlePath?: string;
  links: Link[];
}) {
  return (
    <section>
      <Txt as="h2" fontSize={13} fontWeight="bold" color={colors.grayBold} width={100}>
        {titlePath != null ? (
          <Link href={titlePath}>
            <Txt as="a" cursor="pointer">
              {title}
            </Txt>
          </Link>
        ) : (
          title
        )}
      </Txt>
      <Stack
        as="ul"
        gutter={8}
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {links.map(({ name, path }) => (
          <Box key={name + path} as="li" width={100} height={20} marginTop={8}>
            <Link href={path}>
              <Txt as="a" fontSize={13} lineHeight="20px" color={colors.grayBold} cursor="pointer">
                {name}
              </Txt>
            </Link>
          </Box>
        ))}
      </Stack>
    </section>
  );
}

const InfoText = ({ children }: { children: ReactNode }) => (
  <Txt fontSize={12} lineHeight="18px" color={colors.grayLight}>
    {children}
  </Txt>
);
