import type { UserAuth } from '~/models/user';
import { api } from '~/remotes/api';

export interface WithAccessToken {
  accessToken: string;
}

export async function checkAuth({ accessToken }: WithAccessToken) {
  const { data } = await api.get<UserAuth>('/auth.check', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
}

export async function login(payload: { email: string; password: string }) {
  const { data } = await api.post<UserAuth>('/auth.login', payload);

  return data;
}

export async function logout({ accessToken }: WithAccessToken) {
  await api.post(
    '/auth.logout',
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
}
