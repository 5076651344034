import { createPersistence } from '~/utils/persistence';
import { createSessionStorage } from '~/utils/storage';

export interface MyPersistenceData {
  passwordCheckedForEditProfile: string;
}

export const myPersistence = createPersistence<MyPersistenceData>('@@wm/my', {
  provider: createSessionStorage(),
});
